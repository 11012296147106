import hash from '@emotion/hash';
import { removeCSS, updateCSS } from '../../vc-util/Dom/dynamicCSS';
import canUseDom from '../canUseDom';
export function flattenToken(token) {
  let str = '';
  Object.keys(token).forEach(key => {
    const value = token[key];
    str += key;
    if (value && typeof value === 'object') {
      str += flattenToken(value);
    } else {
      str += value;
    }
  });
  return str;
}
/**
 * Convert derivative token to key string
 */
export function token2key(token, salt) {
  return hash(`${salt}_${flattenToken(token)}`);
}
const layerKey = `layer-${Date.now()}-${Math.random()}`.replace(/\./g, '');
const layerWidth = '903px';
function supportSelector(styleStr, handleElement) {
  var _a;
  if (canUseDom()) {
    updateCSS(styleStr, layerKey);
    const ele = document.createElement('div');
    ele.style.position = 'fixed';
    ele.style.left = '0';
    ele.style.top = '0';
    handleElement === null || handleElement === void 0 ? void 0 : handleElement(ele);
    document.body.appendChild(ele);
    if (process.env.NODE_ENV !== 'production') {
      ele.innerHTML = 'Test';
      ele.style.zIndex = '9999999';
    }
    const support = getComputedStyle(ele).width === layerWidth;
    (_a = ele.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(ele);
    removeCSS(layerKey);
    return support;
  }
  return false;
}
let canLayer = undefined;
export function supportLayer() {
  if (canLayer === undefined) {
    canLayer = supportSelector(`@layer ${layerKey} { .${layerKey} { width: ${layerWidth}!important; } }`, ele => {
      ele.className = layerKey;
    });
  }
  return canLayer;
}