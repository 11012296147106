<template>
  <div>
    <Form class="form"
          :label-col="{ span: 8 }"
          :model="formState"
          :rules="rules"
          @finish="onFinish"
          autocomplete="off">
      <FormItem
          label="用户名"
          name="username"
      >
        <Input placeholder="请输入用户名或手机号" v-model:value="formState.username"/>
      </FormItem>

      <FormItem
          label="图形验证码"
          name="validateCode"
      >
        <Input v-model:value="formState.validateCode">
          <template #suffix>
            <Validator ref="validator"></Validator>
          </template>
        </Input>
      </FormItem>

      <FormItem
          label="短信验证码"
          name="smsCode"
      >
        <Row>
          <Col :span="16">
            <Input v-model:value="formState.smsCode" />
          </Col>
          <Col :span="8">
            <Button type="primary" @click="sendSms">发送验证码</Button>
          </Col>
        </Row>
      </FormItem>

      <FormItem name="remember" :wrapper-col="{ offset: 8, span: 16 }">
        <Checkbox v-model:checked="formState.remember">记住我</Checkbox>
      </FormItem>

      <FormItem :wrapper-col="{ offset: 8, span: 16 }">
        <Button type="primary" html-type="submit">提交</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script setup lang="ts">
import {Button, Col, Row, Checkbox, Form, FormItem, Input, notification, Modal} from "ant-design-vue";
import {reactive, ref} from "vue";
import Validator from "./components/Validator.vue";

const validator = ref()

const formState = reactive({
  username: '',
  validateCode: '',
  smsCode: '',
  remember: true,
})

const rules = ref({
  username: [{required: true, message: '请输入用户名'}],
  validateCode: [{required: true, message: '请输入图形验证码'}],
  smsCode: [{required: true, message: '请输入短信验证码'}],
})

const onFinish = (formState) => {
  notification.warn({
    message: "用户名与密码不匹配"
  })
}

const sendSms = () => {
  if (!validator.value.validate(formState.validateCode)){
    notification.warn({
      message: "验证码错误",
    })
  }
}
</script>

<style lang="scss">
.form {
  label {
    color: #FFF !important;
  }
}
</style>