<script setup lang="ts">

import {onMounted, ref} from "vue";

const canvas = ref()

const realCode = ref()

function verify() {
  //获取canvas的dom对象
  var cas = canvas.value;
  //获取上下文对象
  var ctx = cas.getContext('2d');
  //指定接收的字符串
  realCode.value = randstr(4);

  //绘制矩形，作背景色
  ctx.fillStyle = randomColor();
  //绘制实心矩形
  ctx.fillRect(0, 0, 130, 45)

  //字体样式的属性要遵循css中的书写顺序
  ctx.font = '900 24px 宋体'
  //设置字体颜色
  ctx.fillStyle = '#fff';
  //绘制文字
  ctx.fillText(realCode.value, 20, 30);
}


//指定范围的随机函数：返回指定范围内的随机整数
function rand(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

//返回随机字符串
function randstr(count) {
  var str = '1234567890abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz';
  var code = '';
  for (var i = 0; i < count; i++) {

    var index = rand(0, str.length - 1)
    code += str.charAt(index) + ' ';
  }
  return code
}

const randomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  do {
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  } while (color === '#FFFFFF' || color === '#000000');
  return color;
}

onMounted(() => {
  verify()
})

defineExpose({
  validate: (code) => {
    return code === realCode.value
  }
})

</script>

<template>
  <div class="captcha" @click="verify">
    <canvas width="130" height="45" class="canvas" ref="canvas"></canvas>
  </div>
</template>

<style scoped lang="scss">
.captcha {
  width: 125px;
  height: 45px;
  cursor: pointer;
}
</style>